{
  const typingInterval = 200;

  async function liveReload() {
    async function changeCss (color, colorText) {
      if (!$('.ui-intro.active')) {
        await utils.wait(500);
        return;
      }
      await utils.eraseAndType(text, colorText, typingInterval);
      await utils.wait(500);
      await arrow._.transition({
        opacity: '1',
        transform: 'translateY(0)'
      }, 200);
      await box._.transition({
        background: color
      }, 300);
      await arrow._.transition({
        opacity: '0',
        transform: 'translateY(10px)'
        // transform: '(-20deg)'
      }, 200);
      await arrow._.transition({
        transform: 'translateY(-10px)'
      }, 500);
      await utils.wait(3000);
    }
    const liveReload = $('#liveReload');
    const text = $('.text .inner', liveReload);
    const box = $('.box', liveReload);
    const arrow = $('.arrow', liveReload);

    await changeCss('blue', 'ue');
    await changeCss('#222', 'ack');
  }
  (function liveReloadLooped() {
    Promise.resolve()
      .then(() => liveReload())
      .then(() => liveReloadLooped());
  })();
}
